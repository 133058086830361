

























import { Component, Prop, Vue } from 'vue-property-decorator';
import sdk from '@/sdk/InstoreSdk';
import { Message } from 'element-ui';

// banner组件通信传值设定：
// initOptions：swiper初始化相关参数
// page：getHeaderBanner的传参
// initSwiper：是否为轮播，默认非轮播
// data：自定义banner数据

@Component
export default class InsBanner extends Vue {
  @Prop() private initOptions!: object;
  @Prop({ default: '' }) private page!: string;
  @Prop({ default: false }) private initSwiper!: boolean;
  @Prop() private data!: any;
  bannerList:any[] = [];
  bannerImg: string = '';

  swiperOption: object = {
    autoplay: 1000,
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    }
  };

  getBanner () {
    var _this = this;
    var page = 'Home';
    this.$Api.promotion.getPromotion('Home', 0).then(result => {
      _this.bannerList = result.Promotion.BannerList;
      _this.bannerList.forEach(function (item) {
        var a = item.Content.split('*');
        _this.$set(item, 'Title', a);
      });
    });
  }

  created () {
    if (this.initOptions) {
      this.swiperOption = this.initOptions;
    }
  }

  mounted () {
    if (this.page) {
      this.getBanner();
    } else {
      if (this.initSwiper) {
        this.bannerList = this.data;
      } else {
        if (typeof (this.data) === 'object') {
          this.bannerImg = this.data[0].Image;
        } else if (typeof (this.data) === 'string') {
          this.bannerImg = this.data;
        }
      }
    }
  }
}
